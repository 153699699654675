<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryParams.type" filterable placeholder="请选择类型">

          <el-option  v-for="item in typeList"
                      :key="item.value"
                      :label="item.name"
                      :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="标题" prop="title">
        <el-input
            v-model="queryParams.title"
            placeholder="请输入标题"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addArticleDialog()"
        >新增</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
        >修改</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
        >删除</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="dialogFormGrap = true"
        >采集视频</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <!--    采集公众号输入框-->
    <el-dialog title="公众号采集" :visible.sync="dialogFormGrap">
      <el-form ref="gradParams">
        <el-form-item label="公众号链接（支持腾讯视频）" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="gradParams.url"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormGrap = false">取 消</el-button>
        <el-button type="primary" @click="grabDataByGzh()">确 定</el-button>
      </div>
    </el-dialog>

    <!--   采集公众号后文章内容(编辑)-->
    <el-dialog title="视频内容" :visible.sync="dialogText">
      <!--      <vue-ueditor-wrap v-model="this.form.ti"></vue-ueditor-wrap>-->
      <el-form ref="form" :model="form" :rules="rules">

        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="封面" :label-width="formLabelWidth">
          <!--        <el-input type="textarea" v-model="form.cover"></el-input>-->
          <el-input v-model="form.cover" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.cover" width="260" height="150" />
          </template>
          <el-upload
              class="upload-demo"
              :action="uploadImgUrl"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>

        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select v-model="form.type" filterable placeholder="请选择类型">

            <el-option  v-for="item in typeList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="写作时间" :label-width="formLabelWidth">
          <el-date-picker
              v-model="form.writingTime"
              type="datetime"
              placeholder="日期时间用于文章排序"
              default-time="12:00:00">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="详情页模式" :label-width="formLabelWidth">
          <el-input placeholder="1、B站，2、抖音" v-model="form.play"></el-input>
        </el-form-item>
        <el-form-item label="是否加激励视频" :label-width="formLabelWidth">
          <template >
            <el-switch
                v-model="form.isAd"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="changeInformationStatus($event)">
            </el-switch>
          </template>
        </el-form-item>
        <el-form-item label="是否激励弹窗（绿色有）" :label-width="formLabelWidth">
          <template >
            <el-switch
                v-model="form.adPop"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="changeInformationStatus($event)">
            </el-switch>
          </template>
        </el-form-item>
        <el-form-item label="视频链接" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.content"></el-input>
        </el-form-item>

        <el-form-item label="视频总时长（分）" :label-width="formLabelWidth">
          <el-input v-model="form.duration"></el-input>
        </el-form-item>



      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogText = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保存至数据库</el-button>
      </div>
    </el-dialog>


    <!--    采集公众号输入框-->
    <el-dialog title="公众号采集" :visible.sync="dialogFormGrap">
      <el-form ref="gradParams">
        <el-form-item label="公众号链接" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="gradParams.url"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormGrap = false">取 消</el-button>
        <el-button type="primary" @click="grabDataByGzh()">确 定</el-button>
      </div>
    </el-dialog>

    <!--   采集公众号后文章内容(新增)-->
    <el-dialog title="视频内容" :visible.sync="dialogTextAdd">

      <el-form ref="form" :model="form" :rules="rules">

        <el-form-item label="标题" :label-width="formLabelWidth">
          <el-input type="textarea" v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="封面" :label-width="formLabelWidth">
          <!--        <el-input type="textarea" v-model="form.cover"></el-input>-->
          <el-input v-model="form.cover" placeholder="和本地上传二选一" />
          <template>
            　<img :src="form.cover" width="260" height="150" />
          </template>
          <el-upload
              class="upload-demo"
              :action="uploadImgUrl"
              :on-success="handleAvatarSuccess"
              multiple
              :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
        </el-form-item>
        <el-form-item label="类型" :label-width="formLabelWidth">
          <el-select v-model="form.type" filterable placeholder="请选择类型">

            <el-option  v-for="item in typeList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="写作时间" :label-width="formLabelWidth">
          <el-date-picker
              v-model="form.writingTime"
              type="datetime"
              placeholder="日期时间用于文章排序"
              default-time="12:00:00">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="广告模式" :label-width="formLabelWidth">
          <el-input placeholder="1、B站，2、抖音，3、强弹（违规风险）" v-model="form.play"></el-input>
        </el-form-item>
        <el-form-item label="是否加激励视频" :label-width="formLabelWidth">
          <template >
            <el-switch
                v-model="form.isAd"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="1"
                :inactive-value="0"
                @change="changeInformationStatus($event)">
            </el-switch>
          </template>
        </el-form-item>

        <el-form-item label="视频链接" :label-width="formLabelWidth">
          <!--          <el-input type="textarea" v-model="form.content"></el-input>-->
          <el-input v-model="form.content" placeholder="和本地上传二选一" />

          <el-upload
              class="upload-demo"
              :action="uploadImgUrl"
              :on-success="handleAvatarSuccessVideo"
              multiple
              :limit="1"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <!--          <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
          </el-upload>
          <!--          <template >-->
          <!--            <vue-tinymce-->
          <!--                v-model="form.content"-->
          <!--                :setting="setting" />-->

          <!--          </template>-->
        </el-form-item>

        <!--        <el-form-item label="内容" :label-width="formLabelWidth">-->
        <!--          <el-input type="textarea" v-model="form.content"></el-input>-->
        <!--          -->
        <!--        </el-form-item>-->

        <!--        <el-form-item label="音频" :label-width="formLabelWidth">-->
        <!--          <el-input type="textarea" v-model="form.audio"></el-input>-->
        <!--        </el-form-item>-->

      </el-form>

      <!--             <div>-->
      <!--                 <p v-html="this.form.content"></p>-->
      <!--             </div>-->
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogTextAdd = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保存至数据库</el-button>
      </div>
    </el-dialog>

    <!--    文章列表-->
    <el-table v-loading="loading"
              :data="articleList"
              @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" align="center" />
      <!--      <el-table-column-->
      <!--          label="排序"-->
      <!--          type="index"-->
      <!--          width="50">-->
      <!--      </el-table-column>-->
      <el-table-column label="ID" align="center" width="60" prop="id" />
      <el-table-column label="类型" align="center" width="50" prop="name" />

      <el-table-column label="封面" width="60">
        　　<template slot-scope="scope">
        　　　　<img :src="scope.row.cover" width="70" height="40" class="cover-img" id="img" />
        　　</template>
      </el-table-column>
      <el-table-column label="标题" align="center" width="380" prop="title" />

      <el-table-column label="写作时间" align="center" width="140" prop="writingTime" />
      <el-table-column label="点击量" align="center" width="60" prop="priority" />
      <!--      <el-table-column label="音乐" align="center" width="140" prop="audio" />-->
      <el-table-column label="广告点击量" align="center" width="80" prop="readingNum" />
      <el-table-column label="广告拉取失败量" align="center" width="80" prop="adPull" />
      <el-table-column label="广告完播量" align="center" width="80" prop="adComplete" />
      <el-table-column label="转发量" align="center" width="60" prop="forward" />
      <el-table-column label="视频时长" align="center" width="80" prop="duration" />
      <el-table-column label="激励视频" align="center" width="100" prop="isAd" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.isAd"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>ji
      </el-table-column>
      <el-table-column label="是否加激励弹窗（绿色有）" align="center" width="80" prop="adPop" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.adPop"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="详情页样式（1仿B站,2仿抖音）" align="center" width="100" prop="play" />
      <!--      <el-table-column label="是否显示激励弹窗（存在广告违规风险，红色为打开弹窗）" align="center" width="120" prop="audio" >-->
      <!--        <template slot-scope="scoped">-->
      <!--          <el-switch-->
      <!--              v-model="scoped.row.audio"-->
      <!--              active-color="#13ce66"-->
      <!--              inactive-color="#ff4949"-->
      <!--              :active-value="1"-->
      <!--              :inactive-value="0"-->
      <!--              @change="changeInformationStatus($event, scoped.row, scoped.column)">-->
      <!--          </el-switch>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="是否隐藏" align="center" width="100" prop="enable" >
        <template slot-scope="scoped">
          <el-switch
              v-model="scoped.row.enable"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="changeInformationStatus($event, scoped.row, scoped.column)">
          </el-switch>
        </template>
      </el-table-column>
      <!--      <el-table-column label="入库时间" align="center" prop="createTime" />-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
          >删除</el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="addZeroNum(scope.row)"
          >阅读清零</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />


  </div>

</template>

<script>

import { listArticle, getArticle, delArticle, addArticle, updateArticle, exportArticle ,grabDataByGzh,grabVideo,addZeroNum} from "@/api/work/article";
import { listType } from "@/api/work/type";

import Vue from 'vue'

export default {
  name: "Article",
  // components: {RichText},

  data() {
    return {
      uploadImgUrl:process.env.VUE_APP_URL + process.env.VUE_APP_URL_PREFIX+"common/file/uploadToTencentOss",
      //   uploadImgUrl: "https://api.sky1998.cn" + "/common/file/uploadToTencentOss", // 上传的图片服务器地址
      editor: null,
      toolbarConfig: { },
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      //新增文章的框
      dialogTextAdd: false,
      // 总条数
      total: 0,
      // 文章管理表格数据
      articleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 主键字典
      idOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        kind:'video',
        id: null,
        type: null,
        title: null,
        writingTime: null,
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
      },
      //公众号采集弹窗
      // 爬虫参数
      gradParams: {
        url:""
      },
      dialogFormGrap: false,
      formLabelWidth: '120px',
      dialogText:false,
      //文章内容
      content: "",
      //采集后表单内容
      gradContent:{

      },
      //类型列表
      typeList:[],
      //富文本编辑

      setting: {
        menubar: false,
        toolbar: "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        font_formats: '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif',
        fontsize_formats: '11px 12px 14px 16px 18px 24px 36px 48px',
        quickbars_selection_toolbar: "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen quickbars",
        language: 'zh_CN', //本地化设置
        height: 500,
        images_upload_url: 'https://api.sky1998.cn" + "/common/file/uploadToTencentOss'
      }
    };
  },
  created() {
    this.getList();
    this.getDicts("${column.dictType}").then(response => {
      this.idOptions = response.data;
    });
    //获取类型列表
    listType().then(response => {
      this.typeList = response.datas;

    });
    this.dutyDetailClick();
  },
  methods: {
    //新增文章弹窗
    addArticleDialog(){
      this.dialogTextAdd=true
      this.reset();

    },
    handleAvatarSuccess(res, file) {
      this.form.cover=res.datas

    },
    handleAvatarSuccessVideo(res, file) {
      this.form.content=res.datas

    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },

    mounted() {
      // 模拟 ajax 请求，异步渲染编辑器
      setTimeout(() => {
        this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
      }, 1500)
    },
    beforeDestroy() {
      const editor = this.editor
      if (editor == null) return
      editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    //更新状态
    changeInformationStatus(value, { id }, { property }) {
      let parm=property

      let parms={
        id:id,
        [parm]:value
      }
      updateArticle(
          parms
      )

    },
    addZeroNum(row){
      const id = row.id || this.ids
      addZeroNum({
        id:id
      }).then(response=>{
        console.log(response)
        this.getList();
      })
    },
    /** 查询文章管理列表 */
    getList() {
      this.loading = true;
      listArticle(this.queryParams).then(response => {
        console.log(response.datas)
        this.articleList = response.datas;
        this.total = response.total;
        this.loading = false;
      });
    },
    // 主键字典翻译
    idFormat(row, column) {
      return this.selectDictLabel(this.idOptions, row.id);
    },
    //获取公众号数据
    grabDataByGzh(){

      grabVideo(this.gradParams).then(response => {

        this.form=response.datas
        this.form.writingTime=new Date()
        //  this.content=response.datas.content
        // console.log(response.datas)
        this.dialogText=true
        this.dialogFormGrap=false
        this.form.play=1 //激励广告模式
        this.form.type=42
        //将公众号输入框置空
        this.gradParams.url=null;
        // this.articleList = response.datas;
        // this.total = response.total;
        // this.loading = false;
      });

    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        type: null,
        cover: null,
        title: null,
        content: null,
        writingTime: null,
        createTime: null
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      let a = new Date(this.queryParams.writingTime).getTime();
      const date = new Date(a);
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      const dateString = Y + M + D;
      // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
      this.queryParams.writingTime=dateString
      console.log(dateString)
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length!==1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加文章管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {

      this.reset();
      const id = row.id || this.ids
      getArticle({
        id:id
      }).then(response => {
        this.form = response.datas;
        // this.open = true;
        this.content = response.datas.content;
        this.title = "修改文章管理";
      });
      this.dialogText=true
    },

    /** 提交按钮 */
    submitForm() {
      let a = new Date(this.form.writingTime).getTime();
      const date = new Date(a);
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = (date.getDate() < 10 ? '0'+date.getDate() : date.getDate()) + ' ';
      const h = (date.getHours() < 10 ? '0'+date.getHours() : date.getHours()) + ':';
      const m = (date.getMinutes() <10 ? '0'+date.getMinutes() : date.getMinutes()) + ':' ;
      const s = date.getSeconds(); // 秒
      const dateString = Y + M + D + h + m + s;
      this.form.writingTime=dateString
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            console.log(this.form)
            updateArticle(this.form).then(response => {
              this.$modal.msgSuccess("修改成功");
              //  this.open = false;
              this.dialogText=false
              this.getList();
            });
          } else {
            this.form.kind="video"
            addArticle(this.form).then(response => {
              this.$modal.msgSuccess("新增成功");
              // this.open = false;
              this.dialogText=false
              this.dialogTextAdd=false
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除文章管理编号为"' + ids + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return delArticle(ids);
      }).then(() => {
        this.getList();
        this.$modal.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有文章管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function() {
        return exportArticle(queryParams);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>
<style>

/*.cover-img{*/
/*  cursor: pointer;*/
/*  transition: all 0.6s;*/
/*}*/
/*.cover-img:hover{*/
/*  transform: scale(10);*/
/*}*/
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>
